<template>
   <div>
     <v-banner
        single-line
        height="500"
        class="banner"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/banner-fte-pc.png"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="px-6 max-width-1200 height-per-100 d-flex align-center">
          <v-card color="transparent" flat class="flex-grow-1">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  FT引擎
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px">
                  自主研发的三维引擎，可视化便捷操作，让你快速创作虚拟内容
                </v-card-title>
              </div>
            </div>
          </v-card>
          <div class="flex-grow-1">
            <v-img src="https://h5.ophyer.cn/official_website/banner/banner-fte-img.png" width="90%"></v-img>
          </div>
        </v-container>
      </v-img>
    </v-banner>

    <div class="con con1">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title">
          <div>
            <span>强大的功能 助力高效3D内容制作</span>
            <span>3D Content production</span>
          </div>
        </div>
        <v-container fluid>
          <v-row no-gutters justify='space-around'>
            <v-col v-for="(v, k) in list1" :key="k" cols="12" sm="4" >
              <div class="box">
                <img :src='v.icon' />
                <p>{{ v.title }}</p>
                <p>{{ v.text }}</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div class="con con2">
      <div>
        <img src="https://h5.ophyer.cn/official_website/other/fte-con2-img01.png"/>
        <p>技术框架</p>
      </div>
      <div>
        <img src="https://h5.ophyer.cn/official_website/other/fte-con2-img02.png"/>
        <p>功能结构</p>
      </div>
    </div>

    <div class="con con3">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title">
          <div>
            <span>内容创作</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
          <div class="pl-10 borderimg_two">
            <img src="https://h5.ophyer.cn/official_website/other/fte-con3-img01.png"/>
          </div>
          <div class="text-wrap" style="padding-left: 200px">
            <v-card class="pa-0"  flat>
              <v-card-title class="con_tit pa-0 mb-5" >支持多种类型内容创作</v-card-title>
              <v-card-text class="con_text pa-0">动画，灯光，粒子，物理，地形，材质全部都支持，通过强<br>大的物理引擎，实现逼真的物理反馈</v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>
    <div class="con con3" style="background: #fff;">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title">
          <div>
            <span>逻辑开发</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <v-card class="wrap part-one d-flex justify-center" flat style="background: #fff;">
          <div class="pr-10 borderimg" >
            <img src="https://h5.ophyer.cn/official_website/other/fte-con3-img02.png"/>
          </div>
          <div class="text-wrap" style="padding-right:160px">
            <v-card class="pa-0" flat style="background: #fff;">
              <v-card-title  class="con_tit pa-0 mb-5">让场景动起来</v-card-title>
              <v-card-text  class="con_text pa-0">支持蓝图式开发，简单拖拽即可实现逻辑嵌入，提 <br> 供完善的接口，同时支持C#及JS</v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con3">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title">
          <div>
            <span>实时渲染</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
          <div class="pl-10 borderimg_two">
            <img src="https://h5.ophyer.cn/official_website/other/fte-con3-img03.png"/>
          </div>
          <div class="text-wrap" style="padding-left: 200px">
            <v-card class="pa-0"  flat>
              <v-card-title class="con_tit pa-0 mb-5" >实现完美效果</v-card-title>
              <v-card-text class="con_text pa-0">全局光照及混合渲染等诸多强大的渲染技术，让画面的表现 <br> 趋近完美</v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>
    <div class="con con3" style="background: #fff;">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title">
          <div>
            <span>后期处理</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <v-card class="wrap part-one d-flex justify-center" flat style="background: #fff;">
          <div class="pr-10 borderimg" >
            <img src="https://h5.ophyer.cn/official_website/other/fte-con3-img04.png"/>
          </div>
          <div class="text-wrap" style="padding-right:160px">
            <v-card class="pa-0" flat style="background: #fff;">
              <v-card-title  class="con_tit pa-0 mb-5">让内容趋于完美</v-card-title>
              <v-card-text  class="con_text pa-0">支持屏幕反射、景深、色彩校正、全屏泛光、环境光、抗锯 <br> 齿及阳光射线等后期处理</v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>

    <div class="con con3">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title">
          <div>
            <span>支持多平台</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
          <div class="pl-10 borderimg_two">
            <img src="https://h5.ophyer.cn/official_website/other/fte-con3-img05.png"/>
          </div>
          <div class="text-wrap" style="padding-left: 200px">
            <v-card class="pa-0"  flat>
              <v-card-title class="con_tit pa-0 mb-5" >随时随地进行创作</v-card-title>
              <v-card-text class="con_text pa-0">支持安卓，IOS，Mac,Window等常见的操作系统，让你随<br>时随地开始创作</v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>
    <div class="con con3" style="background: #fff;">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title">
          <div>
            <span>海量素材资源</span>
            <span>aPaaS engine</span>
          </div>
        </div>
        <v-card class="wrap part-one d-flex justify-center" flat style="background: #fff;">
          <div class="pr-10 borderimg" >
            <img src="https://h5.ophyer.cn/official_website/other/fte-con3-img06.png"/>
          </div>
          <div class="text-wrap" style="padding-right:160px">
            <v-card class="pa-0" flat style="background: #fff;">
              <v-card-title  class="con_tit pa-0 mb-5">大量云端资源</v-card-title>
              <v-card-text  class="con_text pa-0">拥有丰富的3D模型，场景，贴图，粒子特效等资源，<br/>助力快速创建场景</v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </div>
    <div class="con con4">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="ftyd-public-title">
          <div>
            <span>内容发布</span>
            <span>aPaaS engine</span>
          </div>
          <p>适配VRPN协议，可以驱动各类型XR硬件支持HTML5 WebGL平台，实现5G时代的移动三维创作和展示</p>
        </div>
        <ul>
          <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon01-01.png" alt="">
            <p>PC</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon02-01.png" alt="">
            <p>移动端</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon03-01.png" alt="">
            <p>WEB端</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon04-01.png" alt="">
            <p>VR头显</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon05-01.png" alt="">
            <p>MR头显</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/icon/fte-con4-icon06-01.png" alt="">
            <p>沉浸CAVE</p>
          </li>
        </ul>
        <!-- <ul>
          <li>
            <img src="https://h5.ophyer.cn/official_website/text-icon.png" alt="">
            <p>MR头显</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/text-icon.png" alt="">
            <p>沉浸CAVE</p>
          </li>
          <li>
            <img src="https://h5.ophyer.cn/official_website/text-icon.png" alt="">
            <p>交互终端</p>
          </li>
        </ul> -->
      </v-card>
    </div>
   </div>
</template>

<script>
export default {
  name: "OnlineRetailers",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      list1: [
        {
          icon: 'https://h5.ophyer.cn/official_website/icon/fte-con1-icon01.png',
          title: '操作简单',
          text: '供图形化的编辑页面，让3D场景搭建，炫酷动画的制作变得简单',
        },
        {
          icon: 'https://h5.ophyer.cn/official_website/icon/fte-con1-icon02.png',
          title: '功能全面',
          text: '海量素材，多种内容创作支持，逻辑开发，让创作更随心',
        },
        {
          icon: 'https://h5.ophyer.cn/official_website/icon/fte-con1-icon03.png',
          title: '强大的渲染能力',
          text: '强大的渲染能力，让材质贴图，光效等画面细节都逼真无比',
        }
      ]
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
  
  }
}
</script>

<style scoped lang='scss'>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.banner_title{
  font-size: 60px;
  color: #FFFFFF !important;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}

.con {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
}
.con1 {
  background: #FBFBFB;
  .box {
    width: 300px;
    height: 210px;
    background: #FFFFFF;
    border-radius: 16px;
    margin: 0 20px;
    text-align: center;
    padding: 0 10px;
    box-sizing: border-box;
    p {
      margin-bottom: 0;
    }
    p:nth-of-type(1) {
      margin-top: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    p:nth-of-type(2) {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
  }
} 

.con2 {
  padding-top: 66px;
  div {
    width: 400px;
    text-align: center;
    margin: 0 20px;
  }
  img {
    width: 400px;
    height: 225px;
    border-radius: 10px;
  }
  p {
    margin-bottom: 0;
    margin-top: 15px;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
  }
}
  
.con3 {
  background: #FBFBFB;
  .v-card {
    background: #FBFBFB;
  }
  .borderimg{
    width:600px;
    // height:300px;
    img{
      border-radius: 10px;
      float: right;
    }
  }
  .borderimg_two{
    width: 600px;
    // height: 300px;

    img{
      border-radius: 10px;
      float: left;
    }
  }
  .part-one{
    margin-bottom: 21px;
    .text-wrap{
      margin-top: -20px;
      width: 600px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top:84px;
        position: relative;
        &::before {
          content: '';
          width: 60px;
          height: 4px;
          background: #000000;
          position: absolute;
          bottom: -10px;
          left: 0;
        }
      }
      .con_text{
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 26px;
      }
    }
  }
}

.con4 {
  background: #FBFBFB;
  ul {
    width: 1000px;
    display: flex;
    justify-content: center;
    // margin-bottom: 40px;
    flex-wrap: wrap;
  }
  li {
    width: 260px;
    height: 170px;
    background: #FFFFFF;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px 20px;
    img {
      width: 100px;
    }
    p {
      margin-top: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
  }
}
</style>